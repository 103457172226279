* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  line-height: normal;
  font-family: "Montserrat", sans-serif;
  overflow-x: hidden;
  /* padding-top: 94px; */
}



/* privacy styling here ... */

.sectiondiv {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.term-container {
  background: #ffffff;
  box-shadow: 0px 4px 100px rgba(0, 0, 0, 0.1);
  border-radius: 24px;
  padding-bottom: 3rem;
  padding-top: 2rem;
}

.main-heading h4 {
  text-align: center;
  padding-top: 2rem;
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 30px;
  color: #333333;
  margin-bottom: 20px;
}

.main-heading p {
  padding-left: 3rem;
  padding-right: 2rem;
  color: #333333;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 25px;
}

.term-title {
  padding-left: 3rem;
  padding-right: 2rem;

  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 30px;
}

.term-title h6 {
  margin-top: 20px;
  margin-bottom: 20px;
  line-height: 25px;
  font-size: 14px;
}

.term-title ol {
  padding-left: 1rem;
  padding-right: 2rem;
  margin-top: 20px;
  margin-bottom: 20px;
  color: #333333;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
}

.term-title ul {
  padding-left: 1rem;
  padding-right: 2rem;
  color: #333333;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
}

.description {
  color: #333333;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  margin-top: 20px;
  margin-bottom: 20px;
}




@media screen and (min-width: 390px) and (max-width: 1080px) {
  .main-heading h4 {
    text-align: center;
  }

  .term-title {
    text-align: center;
    margin-bottom: 20px;
  }
  .term-title ul {
    text-align: left;
    font-size: 14px;
    padding-left: 0;
  }
  .term-title ol {
    text-align: left;
    font-size: 14px;
    padding-left: 0;
  }

  .description {
    padding-left: 0;
  }
}

@media screen and (max-width: 600px) {
  footer {
    text-align: center;
  }

  footer .footer-logo {
    max-width: 100px;
    width: 100%;
  }

  .footer-nav {
    text-align: center;
    margin: 30px 0;
  }

  footer .mt-5 {
    margin: 0 !important;
  }

  footer .footNav {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 15px;
  }
}
