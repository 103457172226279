/* our product features styling here ... */

.ed2100-slide-section {
  background: linear-gradient(
    0deg,
    rgba(0, 212, 255, 0) 35%,
    rgba(248, 248, 250, 1) 35%
  );
}

.Our-L-Partners {
  padding-top: 70px;
  text-align: center;
}

.Our-L-Partners h3 span {
  color: #fca61f;
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
}

.Our-L-Partners h3 {
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
}

.Our-L-Partners p {
  margin-top: 30px;
  color: #333;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 2rem;
}

.Our-L-Partners p strong {
  color: #333;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

.card-title{
color: #333;
text-align: center;
font-size: 18px;
font-weight: 600;
line-height: normal;
}

/*  */

.card-container {
  text-align: center;
}

.feature-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.Our-Learning_partners {
  border-radius: 20px;
  border: 1px solid #d6d6d6;
  background: #fff;
}

.img-box-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 13rem;
  height: 10rem;
}

.img-box-slider img {
  width: 50%;
  /* height: 50%; */
}
.shinkan {

  width: 50% !important;
  height: 50%;
}

.card-txts {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.card-txt h3 {
  margin-top: 10px;
  color: #333;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-transform: capitalize;
}

.card-txt p {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.swiper-button-next {
  background-image: url("../assets/left.svg");
  background-repeat: no-repeat;
  background-size: cover auto;
  background-position: center;
  width: 50px !important;
  height: 100px !important;
  margin-top: -45px !important;
}

.swiper-button-prev {
  background-image: url("../assets/right.svg");
  background-repeat: no-repeat;
  background-size: cover auto;
  background-position: center;
  width: 50px !important;
  height: 100px !important;
  margin-top: -45px !important;
}

.swiper-button-next::after {
  display: none;
}
.swiper-button-prev::after {
  display: none;
}

/* mobile views styling here ... */

@media only screen and (max-width: 600px) {
  .process-container {
    background-size: contain;
  }

  /* .ed2100-slide-section {

  } */

  .ed2100-slide-section {
    background: linear-gradient(
      0deg,
      rgba(0, 212, 255, 0) 50%,
      rgba(248, 248, 250, 1) 50%
    );
  }
  .Our-L-Partners {
    padding-top: 10px;
    margin-top: 30px;
  }
  .Our-L-Partners h3 span {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 51px;
  }

  .Our-L-Partners h3 {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 51px;
  }

  .Our-L-Partners p {
    margin-left: 0;
    margin-top: 0;
    text-align: center;
  }
  .Our-L-Partners p br {
    display: none;
  }

  .feature-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }
  .content {
    padding: 0;
    padding-top: 30px;
  }

  .swiper-button-next {
    background-image: none;
  }

  .swiper-button-prev {
    background-image: none;
  }

  .Our-Learning_partners {
    border-radius: 10px;
    border: 1px solid #d6d6d6;
    background: #fff;;
  }
}


/*  */


@media only screen and (max-width: 768px) {
  .card-title{
    font-size: 16px;
  }

  .Our-L-Partners p br{
    display: none;
  }

  .swiper-button-next {
    background-image: none;
  }

  .swiper-button-prev {
    background-image: none;
  }


} 
@media only screen and (max-width: 820px) {
  .card-title{
    font-size: 16px;
  }

  .Our-L-Partners p br{
    display: none;
  }

  .swiper-button-next {
    background-image: none;
  }

  .swiper-button-prev {
    background-image: none;
  }
} 

.swiper {
  width: 100%;
  height: 100%;
  padding-top: 30px;
}



.swiper-slide {
  text-align: center;
  font-size: 18px;
  /* background: #fff; */

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}


.mobile-Our-S-section .swiper-slide {
  width: 90%;
}

.swiper-slide:nth-child(2n) {
  width: 88%;
}

.swiper-slide:nth-child(3n) {
  width: 88%;
}