* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.be-partenr {
  background-color: #fca61f;
  color: #fff !important;
  border-radius: 8px;
  padding: 10px;
  margin-left: 20px;
}

.ed2100-part-mar {
  margin-top: 2rem;
}

.first-div-md-md {
  padding: 7rem 0;
}

.margin-of-boarding {
  margin-left: 30px;
}

.normal-heading {
  font-weight: 600;
  font-size: 17px;
}

.heading-fine {
  font-weight: 700;
  font-size: 52px;
  color: #dfdfdf;
}

.txt-bl {
  color: #000;
}

.first-div-padding {
  padding: 3rem 0;
}

/* Hero section styling here ... */

.hero-sections {
  background: linear-gradient(117.94deg, #000000 12.57%, #262d35 97.84%);
  border-radius: 0px 0px 250px 250px;
  background-repeat: no-repeat;
  background-size: cover;
}

.hero-heading h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 83px;
  color: #ffffff;
}

.hero-heading span {
  color: #fca61f;
}

.hero-img img {
  width: 100%;
}

.main-font {
  padding: 5px;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 83px;
}

.hero-para {
  padding: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 31px;
  color: #ffffff;
  margin-top: 19px;
}

.normal-para {
  font-weight: 500;
  font-size: 14px;
}

/* End hero section styling */

/* ed2100 stling here ... */

.ed2100-heading {
  font-weight: 700;
  font-size: 3rem;
  color: #000000;
  text-align: center;
}
.ed2100-heading span {
  color: #fca61f;
}

.ed2100para {
  margin-top: 14px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.9rem;
  line-height: 26px;
  color: #666666;
  text-align: center;
  position: relative;
}

.ed2100para::before {
  content: "";
  position: absolute;
  height: 8px;
  width: 10%;
  right: 500px;
  bottom: -20px;
  border-radius: 10px;
  background-color: #fca61f;
}

.txt-cen {
  text-align: center;
}

.first-div {
  background-image: url("../assets/blackImg.png");

  height: 100vh;
  width: 98vw;
}

.heading-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.txt-fl {
  display: flex;
  justify-content: center;
  align-items: center;
}

.first-sec-div {
  background: linear-gradient(117.94deg, #000000 12.57%, #262d35 97.84%);
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 0px 0px 250px 250px;
}

/* .features-first-div-background {
    background-image: url("/img/features.png");
  
    height: 100%;
    width: 100%;
    z-index: 2;
  }
  
  .journey-first-div {
    background-image: url("/img/features.png");
  } */

.features-section-container {
  background: url("../assets//feature-img.png") no-repeat center;
  background-size: cover;
  overflow: hidden;
  padding: 8rem 0;
}

.journey-second-div-color {
  background: url("../assets/ourjaurny-img.png") no-repeat center;
  background-size: cover;
  padding: 8rem 0;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.second-div-of-contact-us {
  background: url("../assets/onboarding-img.png") no-repeat center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 8rem 0;
}

.ed2100-part {
  display: flex;
  justify-content: center;
  align-items: center;
}

.parent-of-ed2100 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ed2100-part-second-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40rem;
}

.partner {
  color: #fca61f;
}

.font-is-real {
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
}

hr.new5 {
  border: 4px solid #fca61f;
  border-radius: 5px;
  width: 4rem;
  position: relative;
}

.partnership-container {
  padding: 8rem 0;
}

.partnership-container .partnership-block {
  display: flex;
  align-items: center;
}

.main-div-of-ed2100-partnership {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.center-the-div {
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.size-of-ed2100-img {
  width: 551px;
  height: 610px;
}

.class-of-all-icons {
  margin-top: 4rem;
}

.text-div-of-ed2100-partnership {
  margin-top: 3rem;
}

.head-of-partnership {
  font-weight: 600;
  font-size: 27px;
  line-height: inherit;
  margin-bottom: 2rem;
}

.partner-pointers {
  display: flex;
  flex-direction: column;
  gap: 35px;
}

.margin-of-icons {
  margin-left: 1rem;
}

.text-color {
  color: #fca61f;
  font-weight: 700;
  font-size: 24px;
}

.icon-of-partnership {
  width: 40.15px;
  height: 45.02px;
}

.learning-partners-colors {
  color: #fca61f;
}

.learning-partners-icons {
  font-style: normal;
  font-weight: 600;
  font-size: 77.3378px;
  line-height: 64px;
  color: #fca61f;
}

.text-font-big {
  font-weight: 700;
  font-size: 52px;
  line-height: 63px;
  text-align: center;
  text-transform: capitalize;
  color: #333333;
}

.benefits-section-header {
  margin-bottom: 2rem;
}

.text-content-of-benefits {
  text-align: center;
  margin: 1rem 0 3rem;
  line-height: 29px;
}

.main-div-of-benefits {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8rem 0;
}

.main-div-of-benefits > .container {
  max-width: 1200px;
  margin: 0 auto;
}

/* Onboarding stylng here ... */

.onboardingapara {
  margin-top: 20px;
  margin-bottom: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  color: #666666;
  position: relative;
  margin-bottom: 58px;
}

.onboardingapara::before {
  content: "";
  position: absolute;
  height: 8px;
  width: 10%;
  right: 500px;
  bottom: -20px;
  border-radius: 10px;
  background-color: #fca61f;
}

/* .boxes-of-benefits {} */

.features-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px;
  padding: 0;
  margin-top: 3rem;
}

.features-container li {
  list-style: none;
}

.features-container li figure {
  background: #fff;
  box-shadow: 6px 9px 7px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  height: 318px;
  text-align: left;
  padding: 42px 25px 26px 25px;
  border: 1px solid #eee;
}

.features-container li figure figcaption h4 {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  margin-top: 20px;
}

.features-container li figure figcaption p {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 25px;
  color: #5f5f5f;
}

.features-title {
  text-align: center;
  color: #fff;
  margin-bottom: 5rem;
}

.features-title h2 {
  color: #fff;
  font-size: 50px;
  font-weight: 700;
}

.features-title p {
  font-weight: 500;
  font-size: 16px;
  color: #dfdfdf;
  text-align: center;
  position: relative;
}
.features-title p::before {
  content: "";
  position: absolute;
  height: 8px;
  width: 10%;
  bottom: -20px;
  border-radius: 10px;
  background-color: #fca61f;
  transform: translate(-50%, 0);
  left: 50%;
}

.txt {
  display: flex;
  justify-content: center;
}

.div-of-text {
  color: #dfdfdf;
  margin-top: 1rem;
}

.div-with-svg {
  width: 70%;
}

hr.new1 {
  border: 2px solid white;
  border-radius: 5px;
  width: 17rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
  /* background: #E1E1E1 */
}

.features-img {
  width: 100%;
  height: 100%;
}

.margin-of-features {
  padding-bottom: 4rem;
}

.journey-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.journey-flex h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 63px;
  text-align: center;
  color: #ffffff;
}

.journey-flex span {
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 63px;
  text-align: center;
  color: #fca61f;
}

.margin-journey {
  margin-top: 7rem;
}

.color-white {
  color: white;
}

.text-on-number {
  font-weight: 600;
  font-size: 77.3378px;
}

.size-of-country {
  font-size: 30px;
  font-weight: 700;
  /* margin-right: 4rem; */
}

.font-of-contact {
  font-weight: 500;
  font-size: 35px;
  color: #dfdfdf;
}

.contact-us-img-of {
  width: 75%;
}

.help-heading {
  font-weight: var(--help-heading-font-weight);
  font-size: var(--help-heading-font-size);
  line-height: 33px;
  text-align: center;
  margin-bottom: 25px;
  color: #ffffff;
}

label {
  color: #fca61f;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
}

.agreebtn {
  color: #ffffff !important;
  font-size: 14px;
}

.form-control {
  background: transparent;
  border: 1px solid rgba(209, 209, 209, 0.3) !important;
  border-radius: 10px;
  height: 3rem;
}

.form-control input:active {
  background: transparent;
}

.text-area {
  background: transparent;
  border: 1px solid rgba(209, 209, 209, 0.3) !important;
  border-radius: 10px;
  color: #ffffff !important;
  padding: 10px;
}

.asterisk {
  color: red;
}

select ::-ms-expand {
  display: none;
}

.btn1 {
  display: flex;
  justify-content: start;
  margin-top: 20px;
}

.navbar-light .navbar-nav .genius-gauge {
  color: #351c77 !important;
  font-size: 18px;
  font-weight: 700;
  list-style: 26px;
  font-style: bold;
  position: relative;
  max-width: max-content;
}

.navbar-light .navbar-nav .genius-gauge::before {
  content: "";
  position: absolute;
  height: 8px;
  width: 90%;
  bottom: -9px;
  border-radius: 10px;
  background-color: #351c77;
}

.navbar-light .navbar-nav .explores {
  color: #351c77 !important;
  font-size: 18px;
  font-weight: 700;
  list-style: 26px;
  font-style: bold;
  position: relative;
  max-width: max-content;
}

.navbar-light .navbar-nav .explores::before {
  content: "";
  position: absolute;
  height: 8px;
  width: 90%;
  bottom: -9px;
  border-radius: 10px;
  background-color: #351c77;
}

.nav-white-of-navbar {
  background-color: white;
}

.width-of-text {
  width: 100%;
}

.wide-of-sv {
  width: 100%;
}

.nav-of-li {
  display: flex;
  align-items: center;
  font-weight: 700;
}
/* 
  .margin-top-of-contact{
  
    padding-top: 2rem;
    } */

.num {
  font-style: normal;
  font-weight: 600;
  font-size: 77.3378px;
  line-height: 64px;
  color: #ffffff;
}

/* contact styling here ... */

.form-group {
  margin-top: 20px;
}

.contact-heading {
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 50px;
  color: #ffffff;
  /* padding-top: 4rem; */
  position: relative;
  margin-bottom: 34px;
}

.contact-heading span {
  color: #fca61f;
}

.contact-heading::before {
  content: "";
  position: absolute;
  height: 8px;
  width: 15%;
  left: 0;
  bottom: -15px;
  border-radius: 10px;
  background-color: #fca61f;
}

.contact-us-form {
  max-width: 500px;
  width: 100%;
  margin-left: auto;
}

.contact-us-form input {
  background-color: transparent;
  color: #ffffff;
}

.contact-us-form input:focus {
  background-color: transparent;
  color: #ffffff;
}

.contact-us-form select {
  -webkit-appearance: none;
  appearance: none;
  background: url("../assets//arrow_back_ios_new.svg") no-repeat;
  background-position: 95%;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  cursor: pointer;
  /* background-color: #000; */
}

.contact-us-form select:focus {
  background-color: #000000;
  box-shadow: none;
  color: #fff;
}

.contact-us-form select:focus-within {
  color: #fff;
}

.contact-us-form select:active {
  color: #fff;
}

/* submit button */

.btn1 {
  display: flex;
  justify-content: center;
}

.d-button {
  border: 2px solid #fca61f;
  width: 162px;
  max-width: 100%;
  border-radius: 8px;
  height: 58px;
  transition: all 0.4s ease-in-out;
  background: transparent;
  position: relative;
  text-decoration: none;
  background-color: #fca61f;
  color: #fff;
}

.d-button span a {
  color: #0e0e0e !important;
  text-decoration: none;
  font-size: 16px;
  font-weight: 550;
}

.d-button:hover span {
  transform-origin: left;
  transform: translate(0px, -29px);
}
/*  */
/* Onboarding styling */

.onboarding-heading h1 {
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 63px;
  text-align: center;
  text-transform: capitalize;
  color: #333333;
}
.onboarding-heading span {
  font-style: normal;
  font-weight: 700;
  font-size: 52px;
  line-height: 63px;
  text-align: center;
  text-transform: capitalize;
  color: #fca61f;
}

.hexagon {
  transition: all 0.4s ease-in-out;
}
.hexagon:hover {
  fill: #fca61f;
}
.hexagon:hover.hexagon-icon {
  color: #fff;
}
.arrow {
  stroke-dashoffset: 1000;
  animation: arrow-animation 20s linear alternate infinite;
}
@keyframes arrow-animation {
  from {
    stroke-dashoffset: 1000;
  }
  to {
    stroke-dashoffset: 0;
  }
}

@media screen and (max-width: 768px) {
}

@media only screen and (min-width: 1024px) {
  .margin-left-lg {
    margin-left: 5rem;
  }

  .padding-of-partnership {
    padding-bottom: 4rem;
  }

  .height-of-nav {
    height: 94px;
    border-bottom: 1px solid #eeeeee;
  }
}

.text-content-of-benefits {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: #333333;
  /* text-align: center; */
  position: relative;
}

.text-content-of-benefits::before {
  content: "";
  position: absolute;
  height: 8px;
  width: 10%;
  bottom: -25px;
  border-radius: 10px;
  background-color: #fca61f;
  transform: translate(-50%, 0);
  left: 50%;
}

.contact-form-large-screen{
display: flex;
}

/* Mobile Devices */

/* /////////////////////////////small screen///////////////////////////////// */

@media only screen and (max-width: 600px) {
  .hero-sections {
    border-radius: 0;
  }

  .contact-form-small-screen{
    display: block;
  }

  .ed2100-heading {
    font-size: 37px;
  }

  .hero-heading h1 {
    font-size: 2.3rem;
    text-align: center;
    line-height: initial;
  }

  .features-title h2 {
    font-size: 37px;
  }

  .hero-para {
    text-align: center;
  }
  .hero-img {
    margin-top: 60px;
  }
  .ed2100-heading h1 {
    font-size: 2.3rem;
    text-align: center;
  }
  .ed2100para {
    font-size: 1rem;
    text-align: center;
  }
  .head-of-partnership {
    font-size: 1.4rem;
    text-align: center;
    margin: 2rem 0;
  }

  .head-of-partnership br {
    display: none;
  }

  .heading-fine {
    font-size: 2.3rem;
    text-align: center;
    padding-top: 0;
  }

  .margin-of-features {
    text-align: center;
  }

  .benefits-section-header h2 {
    font-size: 37px;
    text-align: center;
    line-height: initial;
  }

  .text-content-of-benefits {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 26px;
    color: #333333;
    /* text-align: center; */
    position: relative;
  }

  .text-content-of-benefits::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 15%;
    right: 160px;
    bottom: -25px;
    border-radius: 10px;
    background-color: #fca61f;
  }

  .journey-flex h1 {
    font-size: 2rem;
    text-align: center;
  }
  .journey-flex span {
    font-size: 2.3rem;
    text-align: center;
  }

  .onboarding-heading h1 {
    font-size: 2.3rem;
    text-align: center;
    line-height: initial;
  }
  .onboarding-heading span {
    font-size: 2.3rem;
    text-align: center;
  }

  /* .onbordingimg img {
      width: 100%;
    } */

  .contact-heading {
    font-size: 2.3rem;
    text-align: center;
  }

  .font-of-contact {
    font-size: 1.8rem;
    text-align: center;
    line-height: initial;
  }

  .contact-heading::before {
    left: 68px;
  }
  style {
    margin-top: 0;
  }
  .width-of-first-img {
    width: 100%;
  }

  .btn1 {
    display: flex;
    justify-content: center;
  }

  .heading-flex-sm {
    text-align: center;
  }

  .padding-of-scale-brand {
    padding-top: 6rem;
  }

  .flex-of-revoluntaries {
    display: flex;
    flex-direction: column;
  }

  .ed2100-sm {
    width: 100%;
  }

  .size-of-ed2100-img-sm {
    width: 100%;
    height: 100%;
  }

  .ed2100-part-sm {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 8rem;
  }

  .ed2100-second-div-sm {
    padding-left: 21px;
    text-align: center;
    padding-right: 21px;
  }

  .text-div-of-ed2100-partnership {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .class-of-all-icons-sm {
    margin-left: 1.5rem;
    margin-top: 2rem;
  }

  .div-with-svg-sm {
    margin-left: 3rem;
  }

  .benefits-col-sm {
    display: flex;
    justify-content: center;
  }

  .heading-fine-sm {
    text-align: center;
  }

  .margin-journey-sm {
    margin-top: 1rem;
  }
  .journey-first-div-sm {
    height: 100%;
  }

  .center-the-elements-sm {
    text-align: center;
  }

  .text-fl-sm {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  footer {
    text-align: center;
  }

  footer .footer-logo {
    max-width: 100px;
    width: 100%;
  }

  .footer-nav {
    text-align: center;
    margin: 30px 0;
  }

  footer .mt-5 {
    margin: 0 !important;
  }

  footer .footNav {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 30px;
  }

  footer .footNav li:not(:last-child) {
    margin: 0;
  }

  .first-div-of-contact-us {
    padding-bottom: 0;
  }

  .copyright {
    margin: 0;
  }

  .contact-us-form .form-row {
    display: block;
    padding: 0 10px 0 30px;
    margin: initial;
    width: 100%;
  }

  .contact-us-form .form-row > .col,
  .form-row > [class*="col-"] {
    padding: 0;
  }

  .features-container {
    display: block;
  }

  .features-first-div-background {
    padding: 8rem 0;
  }

  .partnership-container .partnership-block {
    display: block;
  }

  .onboardingapara br {
    display: none;
  }
  .first-div-md-md,
  .main-div-of-benefits,
  .second-div-of-contact-us,
  .features-section-container,
  .journey-second-div-color,
  .partnership-container {
    padding: 4rem 0;
  }

  .features-section-container img {
    width: 60%;
  }

  .features-section-container .row > .col-md-4:nth-of-type(2) {
    order: -2;
    text-align: center;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .mid-dev-size {
    width: 100%;
  }

  .first-div-md {
    height: 64vh;
    width: 98vw;
  }

  .ed2100-text-div-md {
    padding-bottom: 2rem;
  }

  .journey-first-div-md {
    height: 100%;
    width: 100%;
  }

  .grid-md {
    display: grid;

    grid-column: 2 / span 2;
  }

  .ed2100-partnership-md {
    padding-left: 6rem;
    display: flex;
    flex-direction: column;
  }

  .features-container {
    grid-template-columns: repeat(2, 1fr);
  }

  /* .first-sec-div-md {
      height: 64%;
      width: 100%;
    } */

  .onboardingapara::before {
    content: "";
    position: absolute;
    height: 8px;
    width: 15%;
    right: 160px;
    bottom: -25px;
    border-radius: 10px;
    background-color: #fca61f;
  }
  .features-heading-in-box {
    margin-top: 35px;
    border: 2px solid red;
  }
  
}
