#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 {
    display: flex;
    flex-flow: column;
    font-family: Montserrat;
    overflow: hidden;
    padding: 50px 40px;
    justify-content: center;
    /* background -color: rgba(19, 35, 47, 0.9); */
  }

#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 > fieldset {
    margin-bottom: 20px;
    display: flex;
    width: 800px;
    gap: 30px;
    max-width: 100%;
}
#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 > fieldset div div div select {
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border-radius: 15px;
    background-clip: padding-box;
    font-size: 16px;
    padding: 10px 15px;
    border: 1px solid #cbd6e2;
    color: #33475b;
    line-height: 22px;
    margin-bottom: 4px;
}


#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 > fieldset div div input{
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border-radius: 15px;
    background-clip: padding-box;
    font-size: 16px;
    padding: 10px 15px;
    border: 1px solid #cbd6e2;
    color: #33475b;
    line-height: 22px;
    margin-bottom: 4px;
}

#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 > fieldset div div input .hs-error-msg{
    border-color: red;
}


#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 > fieldset div div textarea{
    display: inline-block;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    border-radius: 15px;
    background-clip: padding-box;
    font-size: 16px;
    padding: 10px 15px;
    border: 1px solid #cbd6e2;
    color: #33475b;
    line-height: 22px;
    margin-bottom: 4px;
}

#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 > fieldset div div .hs-fieldtype-textarea {
    height: auto;
    max-width: 100%;
}

#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 > fieldset div div .hs-form__field__input{
    margin-bottom: 4px;
  
}

#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 > fieldset div  {
   width: 100%;
}

#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 > .hs_submit .actions{
    text-align: center;
}

#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 > .hs_submit div input  {
    background: #FCA61F;
    border-color: #FCA61F;
    color: #FFFFFF;
    border: 1px solid #FCA61F;
    line-height: 12px;
    font-size: 12px;
    margin: 18px;
    cursor: pointer;
    display: inline-block;
    font-weight: 700;
    line-height: 12px;
    position: relative;
    text-align: center;
   
    color: #fff;
    border-radius: 10px;
   
    padding: 12px 24px;
 }


 #hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 .hs-error-msgs {
    list-style: none;
    padding: 0;
    margin: 5px 0;
  }
#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 .hs-error-msg,
#hsForm_7a58cce3-e79a-4531-9b0d-9ccd9a32ed14 .hs-main-font-element {
color: red;
font-size: 12px;
}