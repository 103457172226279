:root {
  --primary: #fca61f;
}

.container-fluid {
  width: 100%;
  padding: 0;
}

.hero-sec {
  background: linear-gradient(156deg, #000 0%, #262d35 100%);
  --topb-padding: 121px;
  position: relative;
}

.hero-sec .bot-box-svg {
  width: 100%;
  max-width: 400px;
  height: auto;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
}

.hero-sec .hero-row {
  display: grid;
  grid-template-columns: 1.5fr 1fr;
}

.hero-sec:where(.hero-box1, .hero-box2) {
  padding: var(--topb-padding) 0;
}

.hero-sec :where(.hero-box1, .hero-box2) {
  padding: var(--topb-padding) 0;
}

.hero-sec .hero-box1 {
  --padding-rl: 130px;
  padding-left: var(--padding-rl);
  padding-right: var(--padding-rl);
  background-color: #fff;
  background-image: url("../assets//about-bg.svg");
  background-repeat: repeat;
}

.hero-sec .hero-box2 {
  position: relative;
}

.hero-sec .star-bg-top {
  position: absolute;
  right: 0;
  bottom: 50%;
  height: 50%;
  width: auto;
  transform: scaleY(-1);
}

.hero-sec .star-bg-bottom {
  position: absolute;
  right: 0;
  top: 50%;
  height: 50%;
  width: auto;
}

.hero-sec .star-bg-bottom .main-img {
  width: 100%;
}

.hero-sec p {
  /* padding-left: 30px; */
  position: relative;
  font-size: 24px;
}

.first-p {
  font-weight: 500;
  margin-top: 28px;
  color: #333;
  z-index: 2;
}

.first-p::before {
  content: "";
  display: inline-block;
  top: -10px;
  left: 0;
  position: absolute;
  background-color: var(--primary);
  width: 76%;
  height: 125%;
  z-index: -1;
}

.hero-sec .second-p {
  padding-top: 5px;
  color: #6f6f6f;
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
}

/* .hero-box1{
  margin-left: 100px;
} */

.hero-box1 h1 {
  margin-top: 28px;
  color: #333;
  font-size: 71px;
  font-weight: 800;
  line-height: normal;
}
.hero-box1 h1 span {
  color: #fca61f;
}

/* Media Query start here  */

@media screen and (max-width: 600px) {
  .hero-sec .bot-box-svg {
    display: none;
  }

  .hero-sec .hero-box1 {
    padding: initial;
    padding-left: 20px;
  }

  .hero-box1 {
    order: 2;
  }

  .hero-sec .first-p {
    font-size: 16px;
    font-size: 18px;
    font-weight: 600;
    line-height: 34px;
    color: #333;
    margin-left: 0px;
  }

  .hero-box1 h1 {
    color: #333;
    font-size: 50px;
    font-weight: 800;
    line-height: normal;
  }
  .hero-box1 h1 span {
    color: #fca61f;
    font-size: 50px;
    font-weight: 800;
    line-height: normal;
  }

  .second-p {
    color: #333;
    font-size: 22px;
    font-weight: 600;
    line-height: 34px;
  }

  .hero-box2 {
    order: 1;
  }

  .hero-sec .hero-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .hero-sec :where(.hero-box1, .hero-box2) {
    padding: initial;
  }
}

/* ipad Mini  */

@media screen and (max-width: 768px) {
  .hero-sec .bot-box-svg {
    display: none;
  }

  .main-img {
    width: 100%;
  }

  .hero-sec .hero-box1 {
    padding: initial;
    padding-left: 20px;
  }

  .hero-box1 {
    order: 2;
  }

  .hero-box1 h1 {
    color: #333;
    font-size: 50px;
    font-weight: 800;
    line-height: normal;
  }
  .hero-box1 h1 span {
    color: #fca61f;
    font-size: 50px;
    font-weight: 800;
    line-height: normal;
  }
  .second-p {
    color: #333;
    font-size: 22px;
    font-weight: 700;
    line-height: 34px;
  }

  .hero-box2 {
    order: 1;
  }

  .hero-sec .hero-row {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .hero-sec :where(.hero-box1, .hero-box2) {
    padding: initial;
  }
  .hero-sec p {
    /* padding-left: 30px; */
    position: relative;
    font-size: 30px;
  }

  .hero-sec .first-p {
    font-weight: 500;
    margin-top: 28px;
    color: #333;
    z-index: 1;
  }
  .hero-sec .second-p {
    color: #333;
    font-size: 30px;
    font-weight: 500;
    margin-top: 30px;
    line-height: 34px;
  }
}

@media screen and (min-width:1280) {
  .hero-box1 h1 {
    margin-left: 0 !important;
  }
  
}

@media screen and (min-width: 1366px) {
  .hero-sec {
    background: linear-gradient(156deg, #000 0%, #262d35 100%);
    --topb-padding: 121px;
    position: relative;
  }
  .hero-sec p {
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    font-size: 1.5rem;
  }

  .first-p {
    font-weight: 600;
    margin-top: 28px;
    color: #333;
    z-index: 2;
  }
  .first-p::before {
    content: "";
    display: inline-block;
    top: -10px;
    left: 0;
    position: absolute;
    background-color: var(--primary);
    width: 70%;
    height: 125%;
    z-index: -1;
  }
  .hero-box1 h1 {
    margin-left: 0 !important;
  }
}
.second-p {
  padding-top: 5px;
  color: #6f6f6f;
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
}

@media screen and (min-width: 1440px) {
  .hero-sec {
    background: linear-gradient(156deg, #000 0%, #262d35 100%);
    --topb-padding: 121px;
    position: relative;
  }
  .hero-sec p {
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    font-size: 22px;
  }

  .first-p {
    font-weight: 600;
    margin-top: 28px;
    color: #333;
    z-index: 2;
  }

  .first-p::before {
    content: "";
    display: inline-block;
    top: -10px;
    left: 0;
    position: absolute;
    background-color: var(--primary);
    width: 70%;
    height: 125%;
    z-index: -1;
  }
  .hero-box1 h1 {
    margin-left: 0 !important;
  }
}
.hero-sec .second-p {
  padding-top: 5px;
  color: #6f6f6f;
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
}

@media screen and (min-width: 1440px) and (min-width: 1920px) {


.hero-sec {
    background: linear-gradient(156deg, #000 0%, #262d35 100%);
    --topb-padding: 121px;
    position: relative;
  }
  .hero-sec p {
    padding-left: 30px;
    padding-right: 30px;
    position: relative;
    font-size: 31px;
    margin-left: 175px;

  }

  .first-p {
    font-weight: 600;
    margin-top: 28px;
    color: #333;
    z-index: 2;

  }

  .first-p::before {
    content: "";
    display: inline-block;
    top: -10px;
    left: 0;
    position: absolute;
    background-color: var(--primary);
    width: 70%;
    height: 125%;
    z-index: -1;
  }

  .hero-sec .second-p {
    padding-top: 5px;
    color: #6f6f6f;
    font-size: 30px !important;
    font-weight: 500;
    line-height: 33px;
  }
  .hero-box1 h1 {
    margin-left: 0 !important;
    padding-left: 180px;
  }
}
.hero-sec .second-p {
  padding-top: 5px;
  color: #6f6f6f;
  font-size: 18px;
  font-weight: 500;
  line-height: 33px;
}
  /* .hero-box1 h1 {
    margin-left: 180px;
  } */

  .hero-box2 .main-img {
    width: 100%;
  }


/* @media screen and (max-width: 768px) {
  .title {
    font-size: 1.5em;
    margin: 1em;
  }
  .title-container br {
    display: none;
  }
} */

/* The ed2100 story Section styling here ... */

.stroy-img-sec {
  /* margin-top: 140px; */
  position: relative;
  z-index: 1;
}

.star-bg-story {
  position: absolute;
  right: 0;
  left: 67%;
  top: -14%;
  height: 50%;
  z-index: -1;
  width: 20%;
}
.story-bg-bottom {
  position: absolute;
  right: 20;
  left: 30%;
  bottom: -11%;
  height: 50%;
  z-index: -1;
  width: 20%;
}

.story-main-img {
  width: 100%;
}

.stroy-img-sec h1 {
  position: absolute;
  left: -40%;
  bottom: 43%;
  transform: rotate(-90deg);
  color: #333;
  font-size: 83px;
  font-weight: 900;
  line-height: 100px;
  letter-spacing: 6px;
}
.stroy-img-sec h2 {
  position: absolute;
  left: -9%;
  bottom: 10%;
  transform: rotate(-90deg);
  color: #fca61f;
  font-size: 100px;
  font-weight: 900;
  line-height: 127px;
}

.story-text {
  margin-top: 120px;
  padding-left: 10px;
  padding-right: 40px;
}

.story-text p {
  color: #333;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
}

.horizontal-line {
  margin-left: 250px;
  width: 69%;
  max-width: 309px;
  height: auto;
  border: 2px solid #000 !important;
}

.The-ed2100-story-line {
  margin-left: -350px;
  width: 69%;
  max-width: 309px;
  height: auto;
  border: 2px solid #000 !important;
  margin-top: -30px;
}

.The-ed-story-mobile-device {
  display: none;
}

/*media query styling here  */

@media screen and (max-width: 600px) {
  .The-ed-story-mobile-device {
    display: block;
  }

  .the-story-sec-mapra  {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    margin-top: 25px;
  }

  

  .long-text {
    max-height: 0;
    overflow: hidden;
    display: inline-block;
    transition: max-height 0.5s;
  }

  .expanded {
    max-height: 50rem;
    transition: max-height 2s;
    line-height: 35px;
  }

  .see-more {
    border: none;
    outline: none;
    color: #fca61f;
    font-size: 16px;
    background: transparent;
    /* position: absolute;
    bottom: 0;
    left: 0; */

  }

  .the-story {
    display: none;
  }
  .Our-mission-title h1 {
    padding-left: 20px;
  }
  .Our-mission-title h3 {
    padding-left: 20px;
  }
}

/* ipad Mini  */

@media screen and (max-width: 768px) {
  .The-ed-story-mobile-device {
    display: block;
  }

  .the-story-sec-mapra  {
    color: #333;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    margin-top: 25px;
  }


  .long-text {
    max-height: 0;
    overflow: hidden;
    display: inline-block;
    transition: max-height 0.5s;
  }

  .expanded {
    max-height: 50rem;
    transition: max-height 2s;
  }

  .see-more {
    border: none;
    outline: none;
    color: #fca61f;
    font-size: 16px;
    background: transparent;
    margin-top: -2px;
  }


  .the-story {
    display: none;
  }
  .Our-mission-title h1 {
    margin-top: 2rem;
    padding-left: 20px;
    color: #333;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
  }
  .Our-mission-title h3 {
    padding-left: 20px;
    color: #fca61f;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    margin-bottom: 3rem;
  }

  .our-miss-mobile-img img {
    width: 100%;
  }
}

@media screen and (min-width: 1440px) {
  .stroy-img-sec h1 {
    position: absolute;
    left: -41%;
    bottom: 42%;
    transform: rotate(-90deg);
    color: #333;
    font-size: 100px;
    font-weight: 900;
    line-height: 100px;
    letter-spacing: 6px;
  }
  .stroy-img-sec h2 {
    position: absolute;
    left: -8%;
    bottom: 14%;
    transform: rotate(-90deg);
    color: #fca61f;
    font-size: 110px;
    font-weight: 900;
    line-height: 127px;
  }

  .story-text {
    padding-left: 0;
  }
}

/* ---------------------------------------------------------------
                        Our Vision Section styling  start here ...
---------------------------------------------------------------->  */

.over-vision-sec {
  background: linear-gradient(
    0deg,
    rgba(0, 212, 255, 0) 40%,
    rgba(248, 248, 250, 1) 40%
  );
  margin-top: 100px;
}

.our-vision-content {
  padding-top: 150px;
}

.our-main-img {
  position: relative;
  width: 90%;
  height: 50%;
  background: #fff;
  box-shadow: 0px 14px 120px 0px rgba(0, 0, 0, 0.15);
  max-width: 1098px;
  max-height: 450px;
  z-index: 1;
}

.our-vision-content {
  position: relative;
}

.our-vision-line {
  position: absolute;
  width: 50%;
  max-width: 400px;
  height: auto;
  border: 2px solid #000 !important;
  left: -5%;
  top: 18%;
  transform: rotate(90deg);
  z-index: +3;
}

.our-vision-star {
  padding-left: 20px;
  width: 20%;
  position: absolute;
  left: -5%;
  top: 4%;
}

.our-vision-content h1 {
  color: #333;
  font-size: 120px;
  font-weight: 900;
  line-height: 127px;
}
.our-vision-content h2 {
  color: #fca61f;
  font-size: 130px;
  font-weight: 900;
  line-height: 127px;
  text-transform: uppercase;
}

/*  */

.our-mision {
  display: none;
}

.our-vision-content h2 {
  position: absolute;
  /* left: 30%; */
  right: -16%;
  bottom: 57%;
  transform: rotate(-90deg);
  color: #fca61f;
  font-size: 6.2rem;
  font-weight: 900;
  line-height: 127px;
}
.our-vision-content h1 {
  position: absolute;
  /* left: 30%; */
  right: 0;
  bottom: 44%;
  transform: rotate(-90deg);
  color: #333;
  font-size: 95px;
  font-weight: 900;
  line-height: 127px;
}

.our-vision-content p {
  text-align: center;
  padding: 50px 90px 0px 0px;
  color: #333;
  font-size: 22px;
  font-weight: 500;
  line-height: 37px;
}

.Our-vision-mobile-device {
  display: none;
}

/*media query styling here  */

@media screen and (max-width: 600px) {
  .Our-vision-mobile-device {
    display: block;
  }

  .our-mision {
    display: block;
  }

  .our-vision-sec-mapra p {
    margin-top: 20px;
    color: #333;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    margin-bottom: 3rem;
  }

  .over-vision-sec {
    display: none;
  }
}

/* Ipad Mini  */
@media screen and (max-width: 768px) {
  .Our-vision-mobile-device {
    display: block;
  }

  .our-mision {
    display: block;
  }

  .our-vision-sec-mapra p {
    margin-top: 20px;
    color: #333;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
    margin-bottom: 3rem;
  }

  .over-vision-sec {
    display: none;
  }
}

@media screen and (min-width: 1440px) {
  .our-vision-content h2 {
    position: absolute;
    /* left: 30%; */
    right: -8%;
    bottom: 64%;
    transform: rotate(-90deg);
    color: #fca61f;
    font-size: 6.2rem;
    font-weight: 900;
    line-height: 127px;
  }
  .our-vision-content h1 {
    position: absolute;
    /* left: 30%; */
    right: 6%;
    bottom: 52%;
    transform: rotate(-90deg);
    color: #333;
    font-size: 95px;
    font-weight: 900;
    line-height: 127px;
  }
}

/* <!--------------------------------------------------------------
                        Our mission Section styling here ...
----------------------------------------------------------------> */

.our-mission-sec h1 {
  color: #333;
  font-size: 120px;
  font-weight: 900;
  line-height: 127px;
}

.star-bg-our-miss-sec {
  position: absolute;
  left: 13%;
  /* left: 75%; */
  top: -12%;
  height: 50%;
  z-index: -1;
  width: 30%;
  transform: scaleY(1);
}

.our-mission-sec h1 {
  position: absolute;
  left: -10%;
  bottom: 17%;
  transform: rotate(-90deg);
  color: #333;
  font-size: 100px;
  font-weight: 900;
  line-height: 127px;
  letter-spacing: 6px;
}
.our-mission-sec h2 {
  position: absolute;
  left: -19.5%;
  bottom: 35%;
  transform: rotate(-90deg);
  color: #fca61f;
  font-size: 100px;
  font-weight: 900;
  line-height: 127px;
}

.our-miss-bg-bottom {
  position: absolute;
  right: 20;
  left: 45%;
  bottom: -14%;
  height: 50%;
  z-index: -1;
  width: 70%;
}

.our-miss-main-img {
  position: relative;
  width: 100%;
  margin-left: 70px;
}

.horizontal-line-our-miss {
  position: absolute;
  margin-left: -160px;
  margin-top: 40px;
  z-index: 1;
  width: 100%;
  max-width: 530px;
  height: auto;
  border: 2px solid #000 !important;
}

.our-mission-sec p {
  padding-right: 90px;
  color: #333;
  font-size: 22px;
  font-weight: 400;
  line-height: 42px;
}

.our-mission-sec-mapra {
  margin-top: 100px;
}

#secons-mapra {
  color: #333;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
}

.our-mission-sec span {
  color: #333;
  font-size: 18px;
  font-weight: 800;
  line-height: 32px;
}

.our-miss-mobile-device {
  display: none;
}

/*media query styling here  */

@media screen and (max-width: 600px) {
  .our-mission-sec {
    display: none;
  }

  .our-miss-mobile-device {
    display: block;
  }
  .Our-mission-title h1 {
    color: #333;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    margin-top: 2rem;
  }
  .Our-mission-title h3 {
    color: #fca61f;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
  }
  .our-miss-mobile-img img {
    width: 100%;
    max-width: 384px;
    max-height: 432px;
    /* margin-top: 2rem; */
  }

  .our-mission-sec-mapra {
    margin-top: 26px;
    color: #333;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
  }
}

/* .Our-Mission-mobile-device{
  display: none;
} */

/* Ipad Mini  */
@media screen and (max-width: 768px) {
  .our-mission-sec {
    display: none;
  }

  .our-miss-mobile-device {
    display: block;
  }
  .Our-mission-title h1 {
    color: #333;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    margin-top: 2rem;
  }
  .Our-mission-title h3 {
    color: #fca61f;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
  }
  /* .our-miss-mobile-img img {
    width: 100%;
    max-width: 384px;
    max-height: 432px;
    margin-top: 2rem;
  } */

  .our-mission-sec-mapra {
    margin-top: 26px;
    color: #333;
    font-size: 20px;
    font-weight: 400;
    line-height: 35px;
  }
}

@media screen and (min-width: 1366px) {
  .our-mission-sec h1 {
    position: absolute;
    left: -10%;
    bottom: 17%;
    transform: rotate(-90deg);
    color: #333;
    font-size: 100px;
    font-weight: 900;
    line-height: 127px;
    letter-spacing: 6px;
  }
  .our-mission-sec h2 {
    position: absolute;
    left: -20%;
    bottom: 35%;
    transform: rotate(-90deg);
    color: #fca61f;
    font-size: 100px;
    font-weight: 900;
    line-height: 127px;
  }
}
@media screen and (min-width: 1440px) {
  .our-mission-sec h1 {
    position: absolute;
    left: -7%;
    bottom: 17%;
    transform: rotate(-90deg);
    color: #333;
    font-size: 100px;
    font-weight: 900;
    line-height: 127px;
    letter-spacing: 6px;
  }
  .our-mission-sec h2 {
    position: absolute;
    left: -15%;
    bottom: 32%;
    transform: rotate(-90deg);
    color: #fca61f;
    font-size: 100px;
    font-weight: 900;
    line-height: 127px;
  }
}

/* 
<!--------------------------------------------------------------
                        people we care About start here ...
----------------------------------------------------------------> */

.poeple-we-sec {
  background: linear-gradient(
    0deg,
    rgba(0, 212, 255, 0) 25%,
    rgba(248, 248, 250, 1) 25%
  );
  padding-top: 70px !important;
}

.people-we-care-about-sec h3 {
  /* padding-left: 25px; */
  color: #333;
  font-size: 38px;
  font-weight: 500;
  line-height: 55px;
}
.people-we-care-about-sec h3 span {
  color: #fca61f;
  font-size: 38px;
  font-weight: 500;
  line-height: 55px;
}

.people-we-care-about-sec ul {
  padding: initial;
  margin-top: 80px;
}

.people-we-care-about-sec ul li {
  display: flex;
  gap: 20px;
  list-style: none;
  line-height: 30px;
}
.people-we-care-about-sec ul li img {
  width: 131px;
}
.people-we-care-about-sec ul li p {
  color: #333;
  text-align: center;
  font-size: 20px;
  font-weight: 400;
  padding-top: 15px;
}

.poeple-we-main-img {
  position: relative;
  width: 90%;
}

.star-bg-people-we {
  position: absolute;
  right: 0;
  left: 53%;
  top: -25%;
  height: 50%;
  z-index: -1;
  width: 35%;
  transform: scaleY(1);
}

.poeple-we-bg-bottom {
  position: absolute;
  right: 20;
  left: -73%;
  bottom: -15px;
  height: 50%;
  z-index: -1;
  width: 100%;
}

.people-we-sec {
  position: relative;
  z-index: 1;
  margin-left: -80px;
}

.people-we-sec h1 {
  position: absolute;
  right: -44%;
  bottom: 22%;
  transform: rotate(-90deg);
  color: #333;
  font-size: 100px;
  font-weight: 900;
  line-height: 127px;
}
.people-we-sec h2 {
  position: absolute;
  right: -72%;
  z-index: -1;
  bottom: 41.5%;
  transform: rotate(-90deg);
  color: #fca61f;
  font-size: 100px;
  font-weight: 900;
  line-height: 127px;
  text-transform: uppercase;
}

.horizontal-line-poeple-we {
  position: absolute;
  margin-left: -185px;
  transform: rotate(-90deg);
  top: 18%;
  z-index: 1;
  width: 100%;
  max-width: 550px;
  height: auto;
  border: 2px solid #000 !important;
}

/* .Our-Mission-mobile-device {
  display: none;
} */

.mobile-device-Our-Mission {
  display: none;
}

.mobile-device-people-we-care {
  display: none;
}

.poeple-mobile-device {
  display: none;
}

/* .deskto-version {
  display: none;
} */
/*media query styling here  */

@media screen and (max-width: 600px) {
  /* .mobile-device-Our-Mission {
    display: block;
  } */

  .poeple-mobile-device {
    display: block;
  }

  .md-verison {
    display: none;
  }

  .Our-Mission-mobile-device {
    display: block;
  }
  .deskto-version {
    display: none;
  }
  .poeple-we-sec {
    background: linear-gradient(
      0deg,
      rgba(0, 212, 255, 0) 0%,
      rgba(248, 248, 250, 1) 0%
    );
    padding-top: 0px;
  }
  .people-we-care-about-sec h1 {
    padding-left: 20px;
    color: #333;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    /* padding-top: 2rem; */
  }
  .people-we-care-about-sec h3 {
    color: #fca61f;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    padding-left: 20px;
  }
  .mobile-img img {
    margin-top: 1rem;
    width: 100%;
    height: 100%;
    max-width: 383px;
    max-height: 277px;
    box-shadow: 0px 14px 120px 0px rgba(0, 0, 0, 0.2);
  }
  .people-we-care-about-sec h4 {
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    margin-top: 25px;
  }
  .people-we-care-about-sec h4 br {
    display: none;
  }
  .people-we-care-about-sec h4 span {
    color: #fca61f;
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
  }

  .people-we-care-about-sec ul {
    padding: initial;
    margin-top: 40px;
  }

  /* .people-we-care-about-sec ul li p {
    padding-top: 0;
  } */

  .people-we-care-about-sec ul li img {
    width: 17px;
  }
}

/* Ipad mini  */

@media screen and (min-width: 768px) {
  /* .mobile-device-people-we-care {
    display: block;
  } */

  /* .container-fluid .poeple-we-sec{
    display: block;
  }  */

  /* erorr in ipad mini */
  /* .md-verison {
    display: none;
  }
  

  /* .poeple-mobile-device {
    display: block;
  } */

  .poeple-mobile-device{
    display: none;
  }

   .md-verison {
    display: block;
  }

  .horizontal-line-poeple-we {
    display: none;
  }

  .people-we-care-about-sec ul li img {

    width: initial;
} 



  /* .Our-Mission-mobile-device {
    display: block;
  } */
  /* .deskto-version {
    display: none;
  } */ 

  /* .md-verison{
    display: none;
  } */

  

  /* .star-bg-people-we {
    display: none;
  } */

  /* .poeple-we-bg-bottom {
    display: none;
  } */

  /* .poeple-we-main-img {
  display: none;
} */

  .poeple-we-sec {
    /* background: linear-gradient(
      0deg,
      rgba(0, 212, 255, 0) 0%,
      rgba(248, 248, 250, 1) 0%
    ); */
    padding-top: 0px;
    /* display: none; */
  }
  /* .people-we-care-about-sec h1 {
    padding-left: 20px;
    color: #333;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    padding-top: 2rem;
  }
  .people-we-care-about-sec h3 {
    color: #fca61f;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    padding-left: 20px;
  } */
  
  .mobile-img img {
    margin-top: 1rem;
    width: 100%;
    /* height: 100%; */
    /* max-width: 383px;
    max-height: 277px; */
    box-shadow: 0px 14px 120px 0px rgba(0, 0, 0, 0.2);
  }
  .people-we-care-about-sec h4 {
    color: #333;
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
    margin-top: 25px;
  }
  .people-we-care-about-sec h4 br {
    display: none;
  }
  .people-we-care-about-sec h4 span {
    color: #fca61f;
    font-size: 28px;
    font-weight: 700;
    line-height: 38px;
  }

  .people-we-care-about-sec ul {
    padding: initial;
    margin-top: 40px;
  }

  /* .people-we-care-about-sec ul li p {
    padding-top: 0;
  } */

  /* .people-we-care-about-sec ul li img {
    width: 17px;
  } */
}

@media screen and (min-width: 1366px) {
  .people-we-sec h1 {
    position: absolute;
    right: -44%;
    bottom: 22%;
    transform: rotate(-90deg);
    color: #333;
    font-size: 100px;
    font-weight: 900;
    line-height: 127px;
  }
  .people-we-sec h2 {
    position: absolute;
    right: -72%;
    z-index: -1;
    bottom: 41.5%;
    transform: rotate(-90deg);
    color: #fca61f;
    font-size: 100px;
    font-weight: 900;
    line-height: 127px;
    text-transform: uppercase;
  }
}

@media screen and (min-width: 1440px) {
  .people-we-sec h1 {
    position: absolute;
    right: -36%;
    bottom: 22%;
    transform: rotate(-90deg);
    color: #333;
    font-size: 100px;
    font-weight: 900;
    line-height: 127px;
  }
  .people-we-sec h2 {
    position: absolute;
    right: -60%;
    z-index: -1;
    bottom: 38.5%;
    transform: rotate(-90deg);
    color: #fca61f;
    font-size: 100px;
    font-weight: 900;
    line-height: 127px;
    text-transform: uppercase;
  }
}



/* <!--------------------------------------------------------------
                        Our Team section start  styling here ...
----------------------------------------------------------------> */

.Our-Team {
  padding-top: 100px;
  margin-left: 180px;
  margin-bottom: 4rem;
}

.our-team-profile {
  padding: 0;
  width: 100%;
  max-width: 300px;
  background: #f8f8fa;
  box-shadow: 0px -14px 120px 0px rgba(0, 0, 0, 0.2);
  /* position: relative;
  overflow: hidden; */
}

.our-team-content {
  padding-left: 20px;
  padding-top: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: space-between;
}

.lindin-link {
  margin-top: 20px;
}
.our-team-content h1 {
  color: #333;
  font-size: 24px;
  font-weight: 700;
  line-height: 38px;
}

.our-team-profile p {
  margin-left: 20px;
  margin-bottom: 40px;
  color: #fca61f;
  font-size: 17px;
  font-weight: 500;
  line-height: 25px;
  text-transform: uppercase;
}
/* 
.our-team-profile span {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-color: #fca71fa0;
  width: 100%;
  height: 100%;
  opacity: 0;
  padding: 50px 15px 15px 20px;
  color: white;
  font-size: 15px;
  font-weight: 400;
  transition: all 0.5s linear;
} */

.profile-img-box {
  height: 280px;
  background-color: #333;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  overflow: hidden;
}
 .profile-img-box img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .profile-img-box span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: #fca71fa0;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding: 15px 1px 5px 10px;
    color: white;
    font-size: 12px;
    font-weight: 400;
    transition: all 0.5s linear;
  }


.profile-img-box:hover span {
  opacity: 1;
}

.our-team-titles {
  position: relative;
}

.our-team-titles h1 {
  color: #333;
  font-size: 120px;
  font-weight: 900;
  line-height: 127px;
}
.our-team-titles h2 {
  color: #fca61f;
  font-size: 70px;
  font-weight: 900;
  line-height: 127px;
  text-transform: uppercase;
}

.our-team-titles h1 {
  position: absolute;
  left: -12%;
  top: 150px;
  transform: rotate(-90deg);
  color: #333;
  font-size: 100px;
  font-weight: 900;
  line-height: 127px;
  letter-spacing: 6px;
}
.our-team-titles h2 {
  position: absolute;
  left: -9.4%;
  top: 100px;
  transform: rotate(-90deg);
  color: #fca61f;
  font-size: 100px;
  font-weight: 900;
  line-height: 127px;
}

.profile-imgs:hover {
  background-color: #fca61f;
}
.profile-img:hover {
  background-color: #fca61f;
  opacity: 1;
  transform: translateY(0px);
}

.mobile-device-desktop {
  display: none;
}

/*media query styling here  */

@media screen and (max-width: 600px) {
  .mobile-device-desktop {
    display: block;
    margin-bottom: 3rem;
  }

  .mobile-device-desktop h4 {
    color: #333;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  .mobile-device-desktop h5 {
    color: #fca61f;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    margin-left: 20px;
    margin-bottom: 2rem;
  }

  .our-team-content h1 {
    text-align: left;
  }

  .our-team-profile p {
    text-align: left;
  }

  /* .rajat-img {
   height: initial;
  } */

  .profile-img-box {
    height: 380px;
    background-color: #333;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    overflow: hidden;
  }
    .profile-img-box img {
      width: 100%;
      height: initial;
      object-fit: contain;
    }
  

  .our-team-profile {
    padding: 0;
    width: 100%;
    max-width: 300px;
    background: #f8f8fa;
    box-shadow: 0px -14px 120px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
  }

  .our-team-profile span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: #fca71fa0;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding: 20px 5px 10px 7px;
    color: white;
    font-size: 15px;
    font-weight: 400;
    transition: all 0.5s linear;
    /* display: none; */
  }

  .amit-img {
    height: 80% !important;
  }

  /* .profile-img-boxs img {
    height: 100% !important;

  }

  .amit-img {
    height: 10px;
  }

  /* .our-team-profile{
    display: none;
  } */

  .Our-Team {
    padding-top: 100px;
    margin-left: 0;
    margin-bottom: 3rem;
    display: none;
  }
  /* .our-team-titles h1,
  h2 {
    display: none;
  } */
  .desktop-modes {
    display: none;
  }
  .mobile-device-desktop {
    display: block;
    margin-top: 3rem;
  }
}

/* Ipad mini  */

@media screen and (max-width: 768px) {
  .mobile-device-desktop {
    display: block;
    margin-bottom: 3rem;
  }

  .mobile-device-desktop h4 {
    color: #333;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  .mobile-device-desktop h5 {
    color: #fca61f;
    font-size: 40px;
    font-weight: 900;
    line-height: 40px;
    margin-left: 20px;
    margin-bottom: 2rem;
  }

  .our-team-content h1 {
    text-align: left;
  }

  .our-team-profile p {
    text-align: left;
  }

  /* .rajat-img {
   height: initial;
  } */

  .profile-img-box {
    height: 380px;
    background-color: #333;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
    overflow: hidden;

  }
   .profile-img-box img {
   width: 100%;
   height: initial;
   object-fit: contain;
 }

  .our-team-profile {
    padding: 0;
    width: 100%;
    max-width: 300px;
    background: #f8f8fa;
    box-shadow: 0px -14px 120px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
  }

  .our-team-profile span {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    background-color: #fca71fa0;
    width: 100%;
    height: 100%;
    opacity: 0;
    padding: 20px 5px 10px 7px;
    color: white;
    font-size: 15px;
    font-weight: 400;
    transition: all 0.5s linear;
    /* display: none; */
  }

  .amit-img {
    height: 80% !important;
  }

  /* .profile-img-boxs img {
    height: 100% !important;

  }

  .amit-img {
    height: 10px;
  }

  /* .our-team-profile{
    display: none;
  } */

  .Our-Team {
    padding-top: 100px;
    margin-left: 0;
    margin-bottom: 3rem;
    display: none;
  }
  /* .our-team-titles h1,
  h2 {
    display: none;
  } */
  .desktop-modes {
    display: none;
  }
  .mobile-device-desktop {
    display: block;
    margin-top: 3rem;
  }
}

@media screen and (min-width: 1366px) {
  .our-team-titles h1 {
    position: absolute;
    left: -12%;
    top: 180px;
    transform: rotate(-90deg);
    color: #333;
    font-size: 120px;
    font-weight: 800;
    line-height: 127px;
    letter-spacing: 6px;
  }
  .our-team-titles h2 {
    position: absolute;
    left: -10%;
    top: 100px;
    transform: rotate(-90deg);
    color: #fca61f;
    font-size: 130px;
    font-weight: 900;
    line-height: 127px;
  }
}

@media screen and (min-width: 1440px) {
  .our-team-titles h1 {
    position: absolute;
    left: -15%;
    top: 180px;
    transform: rotate(-90deg);
    color: #333;
    font-size: 120px;
    font-weight: 800;
    line-height: 127px;
    letter-spacing: 6px;
  }
  .our-team-titles h2 {
    position: absolute;
    left: -13%;
    top: 100px;
    transform: rotate(-90deg);
    color: #fca61f;
    font-size: 130px;
    font-weight: 900;
    line-height: 127px;
  }
}

@media screen and (min-width: 1440px) and (min-width: 1920px) {
  .our-team-titles h1 {
    position: absolute;
    left: 1%;
    top: 180px;
    transform: rotate(-90deg);
    color: #333;
    font-size: 120px;
    font-weight: 800;
    line-height: 127px;
    letter-spacing: 6px;
  }
  .our-team-titles h2 {
    position: absolute;
    left: 3.7%;
    top: 100px;
    transform: rotate(-90deg);
    color: #fca61f;
    font-size: 130px;
    font-weight: 900;
    line-height: 127px;
  }

  
  
}

@media screen and (min-width: 1920px) and (min-width: 1920px) {
  .our-team-titles h1 {
    position: absolute;
    left: 1%;
    top: 180px;
    transform: rotate(-90deg);
    color: #333;
    font-size: 120px;
    font-weight: 800;
    line-height: 127px;
    letter-spacing: 6px;
  }
  .our-team-titles h2 {
    position: absolute;
    left: 2.7%;
    top: 100px;
    transform: rotate(-90deg);
    color: #fca61f;
    font-size: 130px;
    font-weight: 900;
    line-height: 127px;
  }

  .Our-Team {
    padding-top: 100px;
    margin-left: 210px;
    margin-bottom: 4rem;
    /* background-color: #fca61f; */
  }
  .our-team-profile {
    padding: 0;
    width: 100%;
    max-width: 350px;
    background: #f8f8fa;
    box-shadow: 0px -14px 120px 0px rgba(0, 0, 0, 0.2);
    position: relative;
    overflow: hidden;
  }
}

/* Our Produts */

/* .OurProducts-mobile-version {
  display: none;
} */

/* @media screen and (max-width: 600px) { 
  .OurProducts-mobile-version {
    display: block;
    margin: 10px;
  }

} */
