/* our product features styling here ... */

.Our-L-section {
  background: linear-gradient(
    0deg,
    rgba(0, 212, 255, 0) 67%,
    rgba(255, 250, 230, 1) 67%
  );
}

.Our-Solution-title {
  padding-top: 70px;
}

.Our-Solution-title h3 {
  display: flex;
  justify-content: center;
}

.Our-Solution-title h3 span {
  display: flex;
  justify-content: center;
}
.Our-S-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.Our-Solution-title h3 span {
  color: #fca61f;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 23px;
}

.Our-Solution-title h3 {
  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
}

.Our-Solution-title p {
  margin-top: 30px;
  color: #333;
  text-align: center;
  font-size: 17px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 2rem;
}

.content p strong {
  color: #333;
  text-align: center;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}

/*  */

.card-container {
  text-align: center;
}

.feature-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.Our-S-card {
  border-radius: 10px;
  border: 1px solid #d6d6d6;
  background: #fff;
  display: flex;
  max-height: 450px;
  max-width: 460px;
  width: 407px;
  height: 491px;
}

.Our-S-card .img-box-f {
  position: relative;
  flex-shrink: 0;
  border-radius: 77px 500px 500px 77px;
  background: #d2fcff;
}

.img-box-f {
  width: 147px;
  height: 212px;
  flex-shrink: 0;
}

.img-box {
  padding: 20px;
}

.card-txt .card-title {
  color: var(--dark-gary, #333);
  text-align: start;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 39px;
  text-transform: capitalize;
}

.card-txt p {
  color: #666;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
/* mobile views styling here ... */

.mobile-views-mode {
  display: none;
}

.mobile-Our-S-section {
  display: none;
}

@media only screen and (max-width: 1280px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  .Our-S-card {
    border-radius: 10px;
    border: 1px solid #d6d6d6;
    background: #fff;
    display: flex;
    max-height: 450px;
    max-width: 460px;
    width: 100%;
    height: 491px;
  }
}

@media only screen and (max-width: 768px) {
  .Our-S-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .mobile-Our-S-section {
    display: block;
  }
}

@media only screen and (max-width: 600px) {
  .Our-L-section {
    background: linear-gradient(
      0deg,
      rgba(0, 212, 255, 0) 40%,
      rgba(248, 248, 250, 1) 40%
    );
  }

  .Our-Solution-title {
    padding-top: 30px;
  }

  /* .Our-S-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr) !important;
    gap: 10px;
  } */

  .Our-Solution-title h3 {
    text-align: center;
    color: #333;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 51px;
    text-transform: capitalize;
  }
  .Our-Solution-title h3 span {
    color: #fca61f;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 51px;
    text-transform: capitalize;
  }
  .Our-S-card {
    /* margin-top: 15px; */
    /* gap: 5px; */
    width: 100%;
    max-width: 100%;
    height: 420px;
  }

  .Our-Solution-title p {
    margin-top: 10px;
    color: #333;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;
    margin-left: 10px;
  }

  .desktop-mode {
    display: none;
  }

  .mobile-views-mode {
    display: block;
    margin-left: -130px;
  }

  .Our-S-row {
    display: none;
  }

  .mobile-Our-S-section {
    display: block;
  }

  br {
    display: block;
  }

  .Our-S-card {
    border-radius: 10px;
    border: 1px solid #d6d6d6;
    background: #fff;
    display: flex;
    max-height: max-content;
  }

  .card-txt .card-title {
    color: var(--dark-gary, #333);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 37px; /* 154.167% */
    text-transform: capitalize;
  }

  .card-mapra {
    color: #5b5b5b;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
  }

  .img-box {
    padding: 10px;
  }
}

@media only screen and (max-width: 820px) {
  .Our-Solution-title p br {
    display: none;
  }

  .Our-Solution-title h3 {
    font-size: 35px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 23px;
  }

  .ed-p-title {
    font-size: 15px;
  }
}

@media only screen and (max-width: 375px) {
  .mobile-views-mode {
    display: none;
    margin-left: -130px;
  }
}
